<template>
  <el-card class="box-card" style="margin-top: 10px;">
    <div class="text item">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="热搜词类型" prop="category">
          <el-radio-group v-model="form.category">
            <el-radio :label="0">{{ "热搜词" }}</el-radio>
            <el-radio :label="1">{{ "搜索框默认词" }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="热搜词名称" style="width: 480px" prop="phrase">
          <el-input
            v-model.trim="form.phrase"
            placeholder="请输入热搜词名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="权重" style="width: 480px" prop="height">
          <el-input
            v-model.trim="form.height"
            placeholder="请输入数值"
            maxlength="4"
          ></el-input>
          <p class="text-info">数字越大，排名越靠前</p>
        </el-form-item>
        <el-form-item label="跳转链接" style="width: 480px" prop="jumpUrl">
          <el-input
            v-model.trim="form.jumpUrl"
            placeholder="请输入跳转链接地址"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即更改</el-button>
          <el-button @click="$router.push('/hotword/list')">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { hotIdWord, hotEdit } from "@/api/hotword";
export default {
  data() {
    return {
      form: {
        phrase: "", //热搜词名称
        category: "0", // 类型
        height: "", //权重
        jumpUrl: "", //跳转链接
      },
      options: [
        { name: "热搜词", id: 0 },
        { name: "搜索框默认词", id: 1 },
      ],
      rules: {
        category: [
          { required: true, message: "请选择热搜词类型", trigger: "blur" },
        ],
        phrase: [
          { required: true, message: "请输入热搜词名称", trigger: "change" },
        ],
      },
    };
  },
  created() {
    hotIdWord({ id: this.$route.query.roleid }).then((res) => {
      this.form = res.data;
    });
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          hotEdit(this.form).then(() => {
            this.$router.push("/hotword/list");
            if (res.status == 10000) {
              this.$notify({
                title: "热搜词",
                message: "编辑成功",
                type: "success",
              });
              this.$router.push("/hotword/list");
            }
          });
        } else {
          return;
        }
      });
    },
  },
};
</script>

<style lang='less'>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 100%;
  height: 100%;
}
.text-info {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #bbbaba;
}
</style>
